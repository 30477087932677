"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildHttpRpcRequest = exports.getSharedHeaders = exports.defaultConfig = exports.cognitoIdentityTransferHandler = void 0;
const clients_1 = require("../../clients");
const composeTransferHandler_1 = require("../../clients/internal/composeTransferHandler");
const retry_1 = require("../../clients/middleware/retry");
const Platform_1 = require("../../Platform");
const detectFramework_1 = require("../../Platform/detectFramework");
const amplifyUrl_1 = require("../../utils/amplifyUrl");
/**
 * The service name used to sign requests if the API requires authentication.
 */
const SERVICE_NAME = 'cognito-identity';
/**
 * The endpoint resolver function that returns the endpoint URL for a given region.
 */
const endpointResolver = ({ region }) => ({
    url: new amplifyUrl_1.AmplifyUrl(`https://cognito-identity.${region}.${(0, clients_1.getDnsSuffix)(region)}`),
});
/**
 * A Cognito Identity-specific middleware that disables caching for all requests.
 */
const disableCacheMiddlewareFactory = () => next => async function disableCacheMiddleware(request) {
    request.headers['cache-control'] = 'no-store';
    return next(request);
};
/**
 * A Cognito Identity-specific transfer handler that does NOT sign requests, and
 * disables caching.
 *
 * @internal
 */
exports.cognitoIdentityTransferHandler = (0, composeTransferHandler_1.composeTransferHandler)(clients_1.unauthenticatedHandler, [disableCacheMiddlewareFactory]);
/**
 * @internal
 */
exports.defaultConfig = {
    service: SERVICE_NAME,
    endpointResolver,
    retryDecider: (0, retry_1.getRetryDecider)(clients_1.parseJsonError),
    computeDelay: retry_1.jitteredBackoff,
    userAgentValue: (0, Platform_1.getAmplifyUserAgent)(),
    cache: 'no-store',
};
(0, detectFramework_1.observeFrameworkChanges)(() => {
    exports.defaultConfig.userAgentValue = (0, Platform_1.getAmplifyUserAgent)();
});
/**
 * @internal
 */
const getSharedHeaders = (operation) => ({
    'content-type': 'application/x-amz-json-1.1',
    'x-amz-target': `AWSCognitoIdentityService.${operation}`,
});
exports.getSharedHeaders = getSharedHeaders;
/**
 * @internal
 */
const buildHttpRpcRequest = ({ url }, headers, body) => ({
    headers,
    url,
    body,
    method: 'POST',
});
exports.buildHttpRpcRequest = buildHttpRpcRequest;
