"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.base64Decoder = void 0;
const globalHelpers_1 = require("../../globalHelpers");
exports.base64Decoder = {
    convert(input) {
        return (0, globalHelpers_1.getAtob)()(input);
    },
};
