"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseJsonError = exports.parseJsonBody = exports.parseMetadata = void 0;
var responseInfo_1 = require("./responseInfo");
Object.defineProperty(exports, "parseMetadata", { enumerable: true, get: function () { return responseInfo_1.parseMetadata; } });
var json_1 = require("./json");
Object.defineProperty(exports, "parseJsonBody", { enumerable: true, get: function () { return json_1.parseJsonBody; } });
Object.defineProperty(exports, "parseJsonError", { enumerable: true, get: function () { return json_1.parseJsonError; } });
