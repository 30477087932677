"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchAuthSession = void 0;
const Amplify_1 = require("../Amplify");
const fetchAuthSession_1 = require("./internal/fetchAuthSession");
const fetchAuthSession = (options) => {
    return (0, fetchAuthSession_1.fetchAuthSession)(Amplify_1.Amplify, options);
};
exports.fetchAuthSession = fetchAuthSession;
