"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.base64Encoder = void 0;
const globalHelpers_1 = require("../../globalHelpers");
const bytesToString_1 = require("./bytesToString");
exports.base64Encoder = {
    convert(input, { urlSafe } = { urlSafe: false }) {
        const inputStr = typeof input === 'string' ? input : (0, bytesToString_1.bytesToString)(input);
        const encodedStr = (0, globalHelpers_1.getBtoa)()(inputStr);
        // see details about the char replacing at https://datatracker.ietf.org/doc/html/rfc4648#section-5
        return urlSafe
            ? encodedStr.replace(/\+/g, '-').replace(/\//g, '_')
            : encodedStr;
    },
};
