"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSessionStorageWithFallback = exports.getLocalStorageWithFallback = void 0;
const InMemoryStorage_1 = require("./InMemoryStorage");
/**
 * @internal
 * @returns Either a reference to window.localStorage or an in-memory storage as fallback
 */
const getLocalStorageWithFallback = () => typeof window !== 'undefined' && window.localStorage
    ? window.localStorage
    : new InMemoryStorage_1.InMemoryStorage();
exports.getLocalStorageWithFallback = getLocalStorageWithFallback;
/**
 * @internal
 * @returns Either a reference to window.sessionStorage or an in-memory storage as fallback
 */
const getSessionStorageWithFallback = () => typeof window !== 'undefined' && window.sessionStorage
    ? window.sessionStorage
    : new InMemoryStorage_1.InMemoryStorage();
exports.getSessionStorageWithFallback = getSessionStorageWithFallback;
