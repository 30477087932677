"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCredentialsForIdentity = void 0;
const clients_1 = require("../../clients");
const internal_1 = require("../../clients/internal");
const base_1 = require("./base");
const getCredentialsForIdentitySerializer = (input, endpoint) => {
    const headers = (0, base_1.getSharedHeaders)('GetCredentialsForIdentity');
    const body = JSON.stringify(input);
    return (0, base_1.buildHttpRpcRequest)(endpoint, headers, body);
};
const getCredentialsForIdentityDeserializer = async (response) => {
    if (response.statusCode >= 300) {
        const error = await (0, clients_1.parseJsonError)(response);
        throw error;
    }
    else {
        const body = await (0, clients_1.parseJsonBody)(response);
        return {
            IdentityId: body.IdentityId,
            Credentials: deserializeCredentials(body.Credentials),
            $metadata: (0, clients_1.parseMetadata)(response),
        };
    }
};
const deserializeCredentials = ({ AccessKeyId, SecretKey, SessionToken, Expiration, } = {}) => {
    return {
        AccessKeyId,
        SecretKey,
        SessionToken,
        Expiration: Expiration && new Date(Expiration * 1000),
    };
};
/**
 * @internal
 */
exports.getCredentialsForIdentity = (0, internal_1.composeServiceApi)(base_1.cognitoIdentityTransferHandler, getCredentialsForIdentitySerializer, getCredentialsForIdentityDeserializer, base_1.defaultConfig);
